<template>
  <div class="form-data">
    <div
      class="min-h-62 d-md-flex align-items-start gap-2 justify-content-between"
    >
      <div class="col-md-12 bg-white py-2">
        <!-- <div class="mb-2">
          <div class="d-md-flex align-items-end justify-content-between">
            <b-form-group
              class="p-0 mb-2 col-md-5"
              label="Selecione o tipo de documento"
            >
              <multiselect
                required
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Selecione o tipo de documento"
                v-model="documentType"
                :options="documentTypeOptions"
                @select="handleSelectDocumentType"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span>{{ option.name }} </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ props.option.name }} </span>
                </template>
              </multiselect>
            </b-form-group>
          </div>
        </div> -->
        <b-form-group
          label="Selecione o documento"
        >
        <v-multiselect-listbox class="w-100"
          :options="documentOptions"
          :reduce-display-property="(option) => option.name"
          :reduce-value-property="(option) => option.id"
          :reduce-group-property="(option) => option.documenttype.name"
          v-model="form.documents"
          search-options-placeholder="Pesquisar"
          selected-options-placeholder="Pesquisar"
          no-options-text="Nenhuma opção"
          selected-no-options-text="Nenhuma opção selecionada"
          no-options-found-text="Nenhum resultado encontrado"
          no-selected-options-found-text="Nenhum resultado encontrado"
          highlight-diff
        >
        </v-multiselect-listbox>
        </b-form-group>
      </div>
    </div>
    <div class="bottom-navigation-service">
      <b-button
        variant="light"
        :to="
          `/homologation/${this.homologationId}/service/${this.serviceId}/edit/details/`
        "
      >
        <span>Voltar</span>
      </b-button>
      <div class="d-flex align-items-center">
        <b-button
        :disabled="form.documents.length === 0"
        variant="primary"
        @click="handleSaveService()"
        >
          Salvar
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  data() {
    return {
      serviceId: "",
      selectedModule: "",
      loadingSubmit: false,
      homologationId: false,
      documentTypeOptions: [],
      documentOptions: [],
      documentType: null,
      form: {
        documents: []
      }
    };
  },
  validations: {
    form: {
      template: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "get_service_scope",
      "show_toast",
      "get_all_documents",
      "edit_service_document",
      "get_all_certificates"
    ]),

    handleSaveService() {
      this.edit_service_document({
        documents: this.form.documents,
        id: this.serviceId,
      }).then(({ status }) => {
        if (status === 201 || status === 200) {
          this.$router.push("/service/list");
        }
      });
    },

    afterSubmit() {
      this.$router.push(
        `/homologation/${this.homologationId}/service/${this.serviceId}/edit/invites/`
      );
    },
    handleSelectModule(module) {
      this.selectedModule = module;
    },
    // handleSelectDocumentType({id}) {
    //   let documenttype = id
    //   this.get_all_certificates({ documenttype }).then(({ results }) => {
    //     this.documentOptions = results
    //   });
    // },

    // getDocuments() {
    //   this.get_all_documents().then(({ data }) => {
    //     this.documentTypeOptions = data.results;
    //   });
    // },

    getDocuments() {
      this.get_all_certificates().then(({data}) => {
        this.documentOptions = data.results
        
      });
    },

    getEdit() {
      this.get_service_scope({ id: this.serviceId }).then((data) => {
        if (data.document_types.length > 0){
          data.document_types.forEach( documentType => {
            documentType.certificates.forEach( document => {
              this.form.documents.push(document.id)
            })
          })
        }
      });
    },
  },
  mounted() {
    this.homologationId = this.$route.params.homologation_id;
    this.serviceId = this.$route.params.service_id;

    if (this.serviceId) {
      this.getEdit();
    }
    this.getDocuments();
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
